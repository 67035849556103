"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const components_1 = require("components");
const utils_1 = require("utils");
function AccountLookupForm({ submitting, handleSubmit, updateField, ssn4ZipDateOfBirthSearch, ssnZipSearch, accountNumberSearch, reset, }) {
    (0, react_1.useEffect)(() => {
        if (ssn4ZipDateOfBirthSearch) {
            reset();
        }
    }, [ssn4ZipDateOfBirthSearch]);
    (0, react_1.useEffect)(() => {
        if (ssnZipSearch) {
            reset();
            updateField('ssnZipSearch', true);
            updateField('ssn4ZipDateOfBirthSearch', false);
        }
    }, [ssnZipSearch]);
    (0, react_1.useEffect)(() => {
        if (accountNumberSearch) {
            reset();
            updateField('accountNumberSearch', true);
            updateField('ssn4ZipDateOfBirthSearch', false);
        }
    }, [accountNumberSearch]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "account-lookup-form", children: (0, jsx_runtime_1.jsxs)("form", { noValidate: true, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "ssn4ZipDateOfBirthSearch", component: lp_components_1.Checkbox, label: (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", bold: true, children: "Zip Code + Last 4 of Social Security Number + Date of Birth" }) }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: "ssn4ZipDateOfBirthSearch", children: (ssn4ZipDateOfBirthSearch) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: ssn4ZipDateOfBirthSearch && ((0, jsx_runtime_1.jsxs)("div", { className: "accountSearchForm", children: [(0, jsx_runtime_1.jsxs)("div", { className: "accountSearchForm__fields", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "zip", component: lp_components_1.Input, label: false, parse: utils_1.removeNonDigits, autoComplete: "off", placeholder: "Zip Code", className: "accountSearchForm__input", maxLength: 5 }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "ssn_last_4", component: lp_components_1.Input, inputType: lp_components_1.MaskedInput, parse: utils_1.removeNonDigits, label: false, placeholder: "Last 4 of SSN / ITIN", className: "accountSearchForm__input", maxLength: 4 }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "dateOfBirth", component: components_1.InputTip, inputType: lp_components_1.MaskedInput, label: false, placeholder: "Birth Day MM/DD/YYYY", className: "accountSearchForm__input", maskOptions: {
                                                date: true,
                                                datePattern: ['m', 'd', 'Y'],
                                            } })] }), (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: `button-warn-outline`, disabled: submitting, submitting: submitting, children: "Search For Account" })] })) })) }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "ssnZipSearch", component: lp_components_1.Checkbox, label: (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", bold: true, children: "Social Security Number + Zip Code" }) }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: "ssnZipSearch", children: (ssnZipSearch) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: ssnZipSearch && ((0, jsx_runtime_1.jsxs)("div", { className: "accountSearchForm ssn-zip", children: [(0, jsx_runtime_1.jsxs)("div", { className: "accountSearchForm__fields", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: components_1.SSNInput, inputType: lp_components_1.MaskedInput, label: false, name: "ssn", maskOptions: {
                                                delimiter: '-',
                                                blocks: [3, 2, 4],
                                            }, parse: utils_1.removeNonDigits, className: "accountSearchForm__input", placeholder: "Social Security Number" }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "zip", component: lp_components_1.Input, label: false, placeholder: "Zip Code", className: "accountSearchForm__input", maxLength: 5 })] }), (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: `button-warn-outline`, disabled: submitting, submitting: submitting, children: "Search For Account" })] })) })) }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "accountNumberSearch", component: lp_components_1.Checkbox, label: (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", bold: true, children: "Account/ Loan Number" }) }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: "accountNumberSearch", children: (accountNumberSearch) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: accountNumberSearch && ((0, jsx_runtime_1.jsxs)("div", { className: "accountSearchForm", children: [(0, jsx_runtime_1.jsx)("div", { className: "accountSearchForm__fields", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "alphaeonAccountNumber", component: lp_components_1.Input, label: false, placeholder: "Account/ Loan Number", className: "accountSearchForm__number" }) }), (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { disabled: submitting, submitting: submitting, className: `button-warn-outline`, children: "Search For Account" })] })) })) })] }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {
    updateField: (field, data) => (0, redux_form_1.change)('accountLookupForm', field, data),
};
function beforeSubmit(formValues) {
    const isoDateOfBirth = (0, utils_1.formatISODateString)(formValues.dateOfBirth);
    return {
        ...formValues,
        dateOfBirth: isoDateOfBirth,
    };
}
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    beforeSubmit,
    name: 'accountLookupForm',
    constraints: {
        zip: {
            numericality: { onlyInteger: true },
            presence: true,
            length: { is: 5 },
        },
        ssn_last_4: {
            numericality: { onlyInteger: true },
            length: { is: 4 },
            presence: true,
        },
        ssn: {
            presence: true,
        },
        dateOfBirth: {
            presence: true,
            length: { is: 10 },
        },
        alphaeonAccountNumber: {
            presence: true,
        },
    },
    submitFilters: {
        reject: [
            'ssn4ZipDateOfBirthSearch',
            'ssnZipSearch',
            'accountNumberSearch',
        ],
    },
    initialValues: {
        ssn4ZipDateOfBirthSearch: true,
    },
}), (0, redux_form_1.formValues)({
    ssn4ZipDateOfBirthSearch: 'ssn4ZipDateOfBirthSearch',
    ssnZipSearch: 'ssnZipSearch',
    accountNumberSearch: 'accountNumberSearch',
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(AccountLookupForm);
