"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const components_1 = require("components");
const merchant_management_v1_api_1 = require("merchant-management-v1-api");
function SaleInitiatorModal({ onConfirm, onClose, accountDetail, }) {
    const [selectedMemberAccountId, setSelectedMemberAccountId] = (0, react_1.useState)(accountDetail?.accountMemberId);
    const [blackListAccount, { isLoading }] = (0, merchant_management_v1_api_1.usePostMerchantManagementV1AccountsAdsAccountIdBlacklistedAccountMutation)();
    const handleOptionChange = (event) => {
        setSelectedMemberAccountId(parseInt(event.target.value));
    };
    const onCloseClick = () => {
        onClose(false);
    };
    const onConfirmClick = () => {
        if (selectedMemberAccountId !== accountDetail.accountMemberId) {
            const selectedBuyer = accountDetail?.authorizedBuyers?.find((buyer) => buyer.accountMemberId === selectedMemberAccountId);
            if (selectedBuyer) {
                const params = {
                    accountId: accountDetail.accountId,
                    authBuyers: [`${selectedBuyer.firstName} ${selectedBuyer.lastName}`],
                    reasonsForVerification: [
                        'Selected Auth Buyer as part of transaction flow',
                    ],
                };
                blackListAccount(params).then(() => {
                    onConfirm(selectedMemberAccountId ?? 0);
                });
            }
        }
        else {
            onConfirm(selectedMemberAccountId ?? 0);
        }
    };
    return ((0, jsx_runtime_1.jsx)(lp_components_1.Modal, { onClose: onCloseClick, overlayClassName: "", className: "modal-inner sale-initiator-modal modal auto", children: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)("div", { className: "sale-initiator-modal__container", children: [(0, jsx_runtime_1.jsx)(components_1.H2, { variant: "headline-medium", children: "PLEASE CHOOSE THE PATIENT FOR THIS TRANSACTION" }), (0, jsx_runtime_1.jsxs)("div", { className: "borrower-container", children: [(0, jsx_runtime_1.jsxs)("div", { className: "primary-borrower", children: [(0, jsx_runtime_1.jsx)(components_1.H3, { variant: "headline-small", children: "Select Primary Borrower" }), (0, jsx_runtime_1.jsx)("div", { className: "radio-group", children: (0, jsx_runtime_1.jsxs)("div", { className: "radio-wrapper", children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", id: accountDetail?.accountMemberId?.toString(), name: "primaryBorrower", value: accountDetail.accountMemberId, checked: selectedMemberAccountId == accountDetail.accountMemberId, onChange: handleOptionChange }), (0, jsx_runtime_1.jsx)("label", { htmlFor: accountDetail?.accountMemberId?.toString(), children: accountDetail?.name?.firstName +
                                                        ' ' +
                                                        accountDetail?.name?.lastName })] }) })] }), (0, jsx_runtime_1.jsx)("div", { className: "separator", children: (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", className: "or", children: "OR" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "authorized-buyer", children: [(0, jsx_runtime_1.jsx)(components_1.H3, { variant: "headline-small", children: "Select Authorized Buyer" }), (0, jsx_runtime_1.jsx)("div", { className: "radio-group", children: accountDetail?.authorizedBuyers?.map((buyer, index) => {
                                            if (buyer.enabled == true) {
                                                return ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "radio-wrapper", children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", id: buyer.accountMemberId?.toString(), name: "authBuyer", value: buyer.accountMemberId, checked: selectedMemberAccountId == buyer.accountMemberId, onChange: handleOptionChange }), (0, jsx_runtime_1.jsx)(components_1.Label, { size: "medium", htmlFor: buyer.accountMemberId?.toString() ?? '', bold: false, children: buyer.firstName + ' ' + buyer.lastName })] }) }, index));
                                            }
                                        }) })] })] }), (0, jsx_runtime_1.jsx)("div", { className: "button-block", children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn", onClick: onConfirmClick, submitting: isLoading, children: "CONFIRM" }) })] }) }) }));
}
exports.default = SaleInitiatorModal;
